<template>
    <div id="page-lap-pelayanan-harian">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                    <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_mulai"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_selesai"
                    ></b-form-datepicker>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Umur
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli Perujuk
                    </template>
                    <multiselect
                      :options="listPoli"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_poli"
                      size="sm"
                      v-model="data_search.poli_perujuk"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tujuan Rujukan
                    </template>
                    <multiselect
                      :options="listRSRujukan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_rs"
                      size="sm"
                      v-model="data_search.tujuan_rujuk"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Asuransi
                    </template>
                    <b-form-select
                      :options="listAsuransi"
                      size="sm"
                      v-model="data_search.asuransi"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Diagnosa
                    </template>
                    <multiselect
                      :options="listDiagnosa"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nm_diag"
                      size="sm"
                      v-model="data_search.diagnosa"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tenaga Medis
                    </template>
                    <multiselect
                      :options="listTenagaMedis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.tenaga_medis"
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - RUJUK EKSTERNAL</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ totalRows }}</strong></h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table-simple responsive class="table-lap-pelayanan-harian">
                    <b-thead>
                      <b-tr>
                        <b-th>No.</b-th>
                        <b-th>Tanggal</b-th>
                        <b-th>Nama Pasien</b-th>
                        <b-th>No. eRM</b-th>
                        <b-th>TTL</b-th>
                        <b-th>Umur</b-th>
                        <b-th>Jenis Kelamin</b-th>
                        <b-th>Asuransi</b-th>
                        <b-th>Diagnosa</b-th>
                        <b-th>Poli Perujuk</b-th>
                        <b-th>Dokter / Tenaga Medis</b-th>
                        <b-th>RS Tujuan</b-th>
                        <b-th>No. Rujukan BPJS</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in listRujukEksternal" :key="idx">
                        <b-td>{{ idx+1 }}</b-td>
                        <b-td>{{ item.tgl }}</b-td>
                        <b-td>{{ item.nama_lengkap }}</b-td>
                        <b-td>{{ item.no_rm }}</b-td>
                        <b-td>{{ item.ttl }}</b-td>
                        <b-td>{{ item.umur }}</b-td>
                        <b-td>{{ item.jenis_kelamin }}</b-td>
                        <b-td>{{ item.asuransi }}</b-td>
                        <b-td>{{ item.diagnosa }}</b-td>
                        <b-td>{{ item.nama_poli_rujuk }}</b-td>
                        <b-td>{{ item.nama_dokter }}</b-td>
                        <b-td>{{ item.nama_tujuan }}</b-td>
                        <b-td>{{ item.no_rujukan }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!-- {{listRujukInternal}} -->
      </b-container>
    </div>
</template>
  
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "kunjungan_pasien",
  components: {
    Multiselect
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      value: null,
      total: null,
      data_search: {
        tanggal_mulai: null,
        tanggal_selesai: null,
        jenis_kelamin: null,
        usia_tahun_mulai: null,
        usia_bulan_mulai: null,
        usia_hari_mulai: null,
        usia_tahun_selesai: null,
        usia_bulan_selesai: null,
        usia_hari_selesai: null,
        asuransi: null,
        poli_perujuk: null,
        tujuan_rujuk: null,
        diagnosa: null,
        tenaga_medis: null,
        ms_poli_rujuk_id: null,
        nama_rs_rujukan: null,
        ms_diagnosa_id: null,
        ms_tenaga_medis_id: null,
      },
      totalRows: 0,
      listRujukEksternal: [],
      listAsuransi: [
        {value: 'bpjs', text: 'BPJS'},
        {value: 'umum', text: 'Umum'},
      ],
      option_jns_kelamin: [
          {value: null, text: "Semua"},
          {value: "L", text: "Laki-laki"},
          {value: "P", text: "Perempuan"},
        ],
      listTenagaMedis: [],
      listPoli: [],
      listDiagnosa: [],
      listRSRujukan: [],
    items: [],
    filter: null,
    tableBusy: false,
    disabled_field: false,
    };
  },
  computed: {
  },
  activated(){
    this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD")
    this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD")
    this.getData()
    this.getDataKunjungan()
  },
  methods: {
    async getData(){
        let vm = this
        let diagnosa = await vm.$axios.post("/ms_diagnosa/list")
        if(diagnosa.data.data.length){
            vm.listDiagnosa = diagnosa.data.data
            for (let i = 0; i < vm.listDiagnosa.length; i++) {
                let x = vm.listDiagnosa[i];
                x.nama_diagnosa = `${x.kd_diag} - ${x.nm_diag}`
            }
        }
        let poli = await vm.$axios.post("/ms_poli/list")
        // console.log(poli, 'ini gudang');
        if(poli.data.data.length){
            vm.listPoli = poli.data.data
        }
        let tenaga_medis = await vm.$axios.post("/tenaga_medis/list")
        console.log(tenaga_medis, 'tenaga medis');
        if(tenaga_medis.data.data.length){
            vm.listTenagaMedis = tenaga_medis.data.data
            // console.log(vm.options_tenaga_medis);
        }
        let rs_rujukan = await vm.$axios.post("/rujuk_external/list_rs_rujukan")
        console.log(rs_rujukan, 'rs rujukan');
        if(rs_rujukan.data.data.length){
            vm.listRSRujukan = rs_rujukan.data.data
            // console.log(vm.options_tenaga_medis);
        }
      },
      getDataKunjungan(){
        let vm = this
        if(vm.data_search.poli_perujuk){
          vm.data_search.ms_poli_rujuk_id = vm.data_search.poli_perujuk.ms_poli_id
        }
        if(vm.data_search.tenaga_medis){
          vm.data_search.ms_tenaga_medis_id = vm.data_search.tenaga_medis.ms_tenaga_medis_id
        }
        if(vm.data_search.diagnosa){
          vm.data_search.ms_diagnosa_id = vm.data_search.diagnosa.ms_diagnosa_id
        }
        if(vm.data_search.tujuan_rujuk){
          vm.data_search.nama_rs_rujukan = vm.data_search.tujuan_rujuk.nama_rs
        }
        vm.$axios.post("/rujuk_external/laporan_rujuk_external", vm.data_search)
        .then((res) => {
          console.log(res, 'ini respon');
          if(res.data.data.length){
            vm.listRujukEksternal = res.data.data
            vm.total = res.data.data.length
            for (let i = 0; i < vm.listRujukEksternal.length; i++) {
                let x = vm.listRujukEksternal[i];
                x.no = i+1
                x.tgl = vm.$moment(x.createdAt).format("DD-MM-YYYY HH:mm")
                x.ttl = `${x.tempat_lahir}, ${vm.$moment(x.tanggal_lahir).format("DD-MM-YYYY")}`
                x.umur = `${x.usia_tahun} tahun ${x.usia_bulan} bulan ${x.usia_hari} hari`
                x.nama_tujuan = x.rs_tujuan_rujukan_bpjs.nmppk
                if(x.kunjungan_sakit == true){
                x.kunjungan = "Sakit" 
                }else {
                x.kunjungan = "Sehat"
                }
            }
          }else {
            vm.items = []
            vm.$store.commit('set_alert', {msg: "TIDAK ADA DATA", variant: "danger", showing: true})
          }
          
        })
        .catch((err) => {
          vm.$store.commit('set_alert', {msg: err.message, variant: "danger", showing: true})
        })
      },
    async reset(){
      const vm = this
      vm.tableBusy = false
      vm.data_search = {
        tanggal_mulai : this.$moment(new Date()).format("YYYY-MM-DD"),
        tanggal_selesai : this.$moment(new Date()).format("YYYY-MM-DD"),
        poli_asal: null,
        poli_tujuan: null,
        usia_tahun_mulai: null,
        usia_bulan_mulai: null,
        usia_hari_mulai: null,
        usia_tahun_selesai: null,
        usia_bulan_selesai: null,
        usia_hari_selesai: null,
        asuransi: null,
        ms_diagnosa_id: null,
        ms_tenaga_medis_id: null,
      }
      vm.getDataKunjungan()
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#page-lap-pelayanan-harian .table-lap-pelayanan-harian th{
  background-color: #D52F65 !important ;
  color: #fff;
  font-weight: 600;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:first-child {
  border-top-left-radius: 8px;
}

#page-lap-pelayanan-harian .table-lap-pelayanan-harian th:last-child {
  border-top-right-radius: 8px;
}
</style>
